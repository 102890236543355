import { CarouselController } from "../controller/carousel-controller";
import { initializeIntervalDurationHandler } from "./interval-duration";
import { findCarouselSliderRootElement } from "../controller/initialize-carousel-controller";
import { CarouselAdditionInitializationBlock } from "../carousel-handles";
import { slideAfterIntervalMilliseconds } from "./interval-slide";
import { addScrollEndEventListener } from "../../../user-agent-fallbacks/scrollend-event/scrollend-event";
import { createIntervalNextTargetSpotter } from "./interval-slide-target";

export const initializeSliderIntervalHandler: CarouselAdditionInitializationBlock =
  (carouselRootElement: HTMLElement, controller: CarouselController) => {
    const sliderRootElement =
      findCarouselSliderRootElement(carouselRootElement);
    let intervalMilliseconds = Number.NaN;
    let runningAbortController: AbortController | undefined;
    const slideTargetFn = createIntervalNextTargetSpotter(controller);

    const cancelIntervalAction = () => {
      runningAbortController?.abort("cancel");
    };

    const scheduleIntervalAction = () => {
      if (runningAbortController) {
        runningAbortController.abort("re-schedule");
      }

      if (
        Number.isNaN(intervalMilliseconds) ||
        !Number.isSafeInteger(intervalMilliseconds) ||
        intervalMilliseconds < 0
      ) {
        return;
      }

      runningAbortController = slideAfterIntervalMilliseconds(
        controller,
        slideTargetFn(),
        intervalMilliseconds
      );
    };

    intervalMilliseconds = initializeIntervalDurationHandler(
      carouselRootElement,
      (newIntervalValue) => {
        intervalMilliseconds = newIntervalValue;
        scheduleIntervalAction();
      }
    );

    sliderRootElement.addEventListener("scroll", cancelIntervalAction);
    rescheduleImplicitScrollToNextAfterScrollingInsideSlider(
      sliderRootElement,
      scheduleIntervalAction
    );
    cancelOnCarouselViewPortExitRescheduleOnCarouselViewPortEntry(
      sliderRootElement,
      scheduleIntervalAction,
      cancelIntervalAction
    );

    scheduleIntervalAction();
  };

const rescheduleImplicitScrollToNextAfterScrollingInsideSlider = (
  sliderRootElement: HTMLElement,
  scheduleFunction: () => void
) => addScrollEndEventListener(sliderRootElement, scheduleFunction);

const cancelOnCarouselViewPortExitRescheduleOnCarouselViewPortEntry = (
  sliderRootElement: HTMLElement,
  scheduleFunction: () => void,
  cancelFunction: () => void
) => {
  new IntersectionObserver(
    (intersections) => {
      for (const intersection of intersections) {
        if (intersection.isIntersecting) {
          scheduleFunction();
        } else {
          cancelFunction();
        }
      }
    },
    {
      threshold: 0,
    }
  ).observe(sliderRootElement);
};
