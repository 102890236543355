import { CarouselController } from "../controller/carousel-controller";
import { AbortedByUserError } from "../aborted-by-user-error";
import { loadSingleImage } from "../image-loading/load-single-image";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

export const slideAfterIntervalMilliseconds = (
  controller: CarouselController,
  targetSlideIndex: number,
  intervalMilliseconds: number
): AbortController => {
  const abortController = new AbortController();
  const upcomingSlideElement =
    controller.getElementOfNthSlide(targetSlideIndex);

  Promise.all([
    prepareContentsOfSlide(upcomingSlideElement, abortController.signal),
    promisedTimeout(intervalMilliseconds, abortController.signal),
  ])
    .then(() => {
      controller.slideTo(targetSlideIndex);
    })
    .catch((error: unknown) => {
      if (!(error instanceof AbortedByUserError)) {
        abortController.abort(error);
      }
    });

  return abortController;
};

const prepareContentsOfSlide = (
  slideElement: HTMLElement,
  abortSignal: AbortSignal
) =>
  Promise.all(
    (function* () {
      for (const imageElement of currentCarouselQuerySelectorAll<HTMLImageElement>(
        slideElement,
        'img[loading="lazy"]'
      )) {
        yield loadSingleImage(imageElement, abortSignal);
      }
    })()
  );

/**
 * {@link setTimeout} / {@link clearTimeout} but wrapped by modern {@link Promise} and {@link AbortController} APIs.
 */
const promisedTimeout = (
  timeoutMilliseconds: number,
  abortSignal: AbortSignal
) =>
  new Promise<void>((resolve, reject) => {
    if (abortSignal.aborted) {
      reject(new AbortedByUserError(abortSignal.reason));
      return;
    }

    let timeoutHandle: ReturnType<typeof setTimeout> | undefined;
    abortSignal.addEventListener("abort", () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
      reject(new AbortedByUserError(abortSignal.reason));
    });
    timeoutHandle = setTimeout(resolve, timeoutMilliseconds);
  });
