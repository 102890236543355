/**
 * Reads the interval duration of a {@link CarouselController carousel} root element once,
 * and calls the given update handler every time the interval duration is updated.
 *
 * If the element has an undefined or invalid interval, {@link Number.NaN} will be used as value.
 */
export const initializeIntervalDurationHandler = (
  carouselRootElement: HTMLElement,
  onUpdate: (newIntervalMilliseconds: number) => void
) => {
  // attribute data-interval
  let currentIntervalValue = readIntervalAttribute(carouselRootElement);

  new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type !== "attributes") {
        continue;
      }

      if (mutation.attributeName !== "data-interval") {
        continue;
      }

      const nextIntervalValue = readIntervalAttribute(carouselRootElement);

      if (currentIntervalValue === nextIntervalValue) {
        continue;
      }

      currentIntervalValue = nextIntervalValue;
      onUpdate(nextIntervalValue);
    }
  }).observe(carouselRootElement, {
    attributes: true,
    attributeFilter: ["data-interval"],
  });

  return currentIntervalValue;
};

const readIntervalAttribute = (carouselRootElement: HTMLElement) =>
  "interval" in carouselRootElement.dataset &&
  carouselRootElement.dataset["interval"] &&
  carouselRootElement.dataset["interval"] !== "false"
    ? Number.parseInt(carouselRootElement.dataset["interval"], 10)
    : Number.NaN;
