import { CarouselController } from "../controller/carousel-controller";

/**
 * Returns a function that returns the index of the next interval auto-scroll
 * target slide.
 *
 * The motion follows a Pendulum.
 */
export const createIntervalNextTargetSpotter = (
  controller: CarouselController
) => {
  let autoSlideDirection: AutoSlideDirection = AUTO_SLIDE_DIRECTIONS.FORWARD;

  return () => {
    const currentIndex = controller.getCurrentIndex();
    const boundary =
      autoSlideDirection === AUTO_SLIDE_DIRECTIONS.FORWARD
        ? controller.getNumberOfSlides() - 1
        : 0;

    if (currentIndex === boundary) {
      autoSlideDirection = toReversedAutoSlideDirection(autoSlideDirection);
    }

    return currentIndex + autoSlideDirection;
  };
};

const AUTO_SLIDE_DIRECTIONS = {
  FORWARD: 1,
  BACKWARD: -1,
} as const;

type AutoSlideDirection =
  (typeof AUTO_SLIDE_DIRECTIONS)[keyof typeof AUTO_SLIDE_DIRECTIONS];

const toReversedAutoSlideDirection = (
  currentDirection: AutoSlideDirection
): AutoSlideDirection =>
  currentDirection === AUTO_SLIDE_DIRECTIONS.FORWARD
    ? AUTO_SLIDE_DIRECTIONS.BACKWARD
    : AUTO_SLIDE_DIRECTIONS.FORWARD;
